import React, { useEffect } from 'react';
import happeningsImg from '../images/happenings.png';

const InstagramFeed = () => {
	return (
		<div className="instagram-feed">
			<div className="instagram-feed__banner">
				<img src={happeningsImg} />
			</div>
		</div>
	)
}

export default InstagramFeed;