import React, { useEffect } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Contact = ({content}) => {
	const contentItems = content.copy.json.content;

	const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="header-copy">{children}</p>
    }
	}

	return (
		<div className="contact content__item" style={{backgroundImage: `url(${content.backgroundImage?.url})`}}>
			<div className="contact__content">
				<h2 className="headline">{content.headline}</h2>
				{documentToReactComponents(contentItems[0], options)}

				<div className="contact__column full-width">
					<p className="column__title">New Business</p>
					<p>NEW YORK — <a href="mailto:newbusiness.ny@72andsunny.com">newbusiness.ny@72andsunny.com</a></p>
					<p>LOS ANGELES — <a href="mailto:newbusiness.la@72andsunny.com">newbusiness.la@72andsunny.com</a></p>
					<p>EUROPE — <a href="mailto:newbusiness.ams@72andsunny.com">newbusiness.ams@72andsunny.com</a></p>
					<p>SYDNEY/ASIA — <a href="mailto:newbusiness.anz@72andsunny.com">newbusiness.anz@72andsunny.com</a></p>
				</div>

				<div className="contact__column">
					<p className="column__title">PR/Comms</p>
					<p><a href="mailto:comms.ny@72andsunny.com">comms.ny@72andsunny.com</a></p>
					<p><a href="mailto:comms.la@72andsunny.com">comms.la@72andsunny.com</a></p>
					<p><a href="mailto:comms.ams@72andsunny.com">comms.ams@72andsunny.com</a></p>
					<p><a href="mailto:comms.anz@72andsunny.com">comms.anz@72andsunny.com</a></p>
				</div>

				<div className="contact__column">
					<p className="column__title">Job Inquiries</p>
					<p><a href="mailto:jobs.ny@72andsunny.com">jobs.ny@72andsunny.com</a></p>
					<p><a href="mailto:jobs.la@72andsunny.com">jobs.la@72andsunny.com</a></p>
					<p><a href="mailto:jobs.ams@72andsunny.com">jobs.ams@72andsunny.com</a></p>
					<p><a href="mailto:jobs.anz@72andsunny.com">jobs.anz@72andsunny.com</a></p>
				</div>
			</div>
		</div>
	)
}

export default Contact;