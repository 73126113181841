import React from 'react';
import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import AppContext from '../contexts/AppContext';
import arrowImg from '../images/arrow.svg';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Hero = (props) => {
	const appContext = React.useContext(AppContext);
	const history = useHistory();
	
  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <h2 className="headline">{children}</h2>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>
    },
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br key={i} />, text])
  }

  const goToPage = (newPage) => {
  	// import(newPage).then(() => history.push(newPage))
  	history.push(newPage)
  }
  
  return (
    <div className={`hero ${props.content.theme} content__item ${props.content.slug ? props.content.slug.replace('/','') : ''}`} style={ props.content.backgroundImage ? {backgroundImage: `url(${props.content.backgroundImage.url})`} : null }>
			<div className="hero__content">
				<h2 className={`headline ${!props.content.copy ? 'only' : ''}`}>{props.content.headline}</h2>
				{
					props.content.copy ? (
						<div className="copy" onClick={ () => props.content.slug !== 'edi' ? goToPage(props.content.slug) : null }>
							{ documentToReactComponents(props.content.copy.json, options) }
							{ props.content.slug ? <div className="expand-icon" onClick={ () => goToPage(props.content.slug) }><img src={arrowImg} /></div> : null }
						</div>
					): null
				}
			</div>
		</div>
  )
}

export default Hero;