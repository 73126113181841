import React from 'react';

const LoopingVideo = ({vidUrl, caption}) => {
  return (
  	<div className={`looping-video`}>
      <video src={vidUrl} autoPlay loop muted />
      {
        caption ? <p className="caption">{caption}</p> : null
      }
  	</div>
  )
}

export default LoopingVideo;