import React from 'react';

const StandardVideo = (props) => {
  return (
    <div className={`standard-video`}>
      <video controls src={props.content?.vidUrl} poster={props.content?.vidThumb} />
      {
        props.content?.videoCaption ? (
          <p className="caption">{props.content?.videoCaption}</p>
        ) : null
      }
    </div>
  )
}

export default StandardVideo;