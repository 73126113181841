import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Parallax, Background } from 'react-parallax';
import AppContext from '../contexts/AppContext';
import { PageContext } from '../contexts/PageContext';
import { WorkContext } from '../contexts/WorkContext';
import Banner from './Banner';
import Carousel from './Carousel';
import TextBlock from './TextBlock';
import TwoColumnModule from './TwoColumnModule';
import LoopingVideo from './LoopingVideo';
import StandardVideo from './StandardVideo';
import Footer from './Footer';
import Hero from './Hero';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const FullPage = () => {
  const appContext = React.useContext(AppContext);
  const pageContext = React.useContext(PageContext);
  const workContext = React.useContext(WorkContext);
  const currentPage = window.location.pathname.split('/').pop();
  const pageCategory = window.location.pathname.split('/')[1];
  const [moreLink, setMoreLink] = useState(null);
  const [nextTitle, setNextTitle] = useState(null);

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <h2 className="headline">{children}</h2>,
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return children[0] !== ' ' ? (
          <p>{
          children.map((c, i) => {
            if(c.type === 'a'){
              return <a key={i} href={c.props.href} target="_blank">{c.props.children[0]}</a>
            } else {
              return children[i]
            }
          })
        }</p>
        ) : null
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const entryObj = pageContext.embeddedEntries.find(o => o.id === node.data.target.sys.id);

        return (
          entryObj ? (
            <React.Fragment>
              {
                entryObj.type === 'Banner' ? (
                  <Banner text={entryObj.bannerText} />
                ) : null
              }
              {
                entryObj.type === 'LoopingVideo' ? (
                  <LoopingVideo vidUrl={entryObj.vidUrl} caption={entryObj.caption} />
                ) : null
              }
              {
                entryObj.type === 'Hero' ? (
                  <Hero content={entryObj} />
                ) : null
              }
              {
                entryObj.type === 'StandardVideo' ? (
                  <StandardVideo content={entryObj} />
                ) : null
              }
              { entryObj.type === 'TwoColumnModule' ? (
                <TwoColumnModule content={entryObj} />
              ) : null
              }
              { entryObj.type === 'TextBlock' ? (
                <TextBlock theme={entryObj.theme} copy={entryObj.copy} />
              ) : null
              }
              {
                entryObj.type !== 'LoopingVideo' && entryObj.type !== 'StandardVideo' && entryObj.type !=='TwoColumnModule' && entryObj.slug !== 'anti-racism' ? (
                  <div className={`page__section embed ${entryObj.theme ? entryObj.theme : ''} ${entryObj.slug ? entryObj.slug : ''}`}>
                      {
                        pageContext.content.length > 0 && entryObj.client ? (
                          <h3>{entryObj.client}</h3>
                        ) : null
                      }
                      {
                        entryObj.description && currentPage !== 'culture' ? (
                          documentToReactComponents(entryObj.description.json, options)
                        ) : null
                      }
                      {
                        entryObj.expanded ? (
                          documentToReactComponents(entryObj.expanded.json, options)
                        ) : null
                      }
                      {
                        pageContext.content.length > 0 && currentPage === 'culture' ? (
                            <div className="embed__description">
                              {documentToReactComponents(entryObj.description.json, options)}
                            </div>
                          ) : null
                      }
                  {
                    entryObj.media && entryObj.type !== 'TwoColumnModule' ? (
                      <div className="section__background">
                        { displayMedia(entryObj.media, entryObj.slug) }
                      </div>
                    ) : null
                  }
                </div>
                ) : null
              }
              </React.Fragment>
          ) : null
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const assetObj = pageContext.embeddedAssets.find(o => o.id === node.data.target.sys.id);

        return (
          assetObj && assetObj.contentType.includes('image') ? (
              <div className="embedded__media">
                {
                  assetObj.description ? (
                    <div className="embedded__media__description">
                      <p className="caption">{assetObj.description}</p>
                    </div>
                  ) : null
                }
                {
                 currentPage === 'about' ? (
                  <Parallax bgImage={assetObj.url} strength={200}>
                    <div className="parallax-image"></div>
                  </Parallax>
                  ) : <img src={assetObj.url} />
                }
              </div>
          ) : assetObj && assetObj.contentType.includes('video') ? (
            <video src={assetObj.url} controls></video>
          ) : null
        )
      }
    },
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br key={i} />, text])

  }

  const displayMedia = (media, slug, top) => {
    if (media) {
      const type = media.contentType.includes('image') ? 'image' : 'video';

      switch (type) {
        case 'image':
          return (
          <React.Fragment>
              <div className="embedded__media">
                <div className="embedded__media__description">
                  <p className="caption">{media.description}</p>
                </div>
                <img src={media.url} alt="" />
              </div>
          </React.Fragment>
          )
          break;
        case 'video':
          return (
          <React.Fragment>
          <video src={media.url} muted autoPlay loop playsInline></video>
          </React.Fragment>
          )
          break;
      }
    }
  }

  useEffect(() => {
    appContext.setCurrentPage(currentPage);
  }, [])

  useEffect(() => {
    if(workContext && workContext.workLoaded){
      setMoreLink(workContext.items[workContext.currentNum === workContext.items.length - 1 ? 0 : workContext.currentNum+1].slug)
      setNextTitle(workContext.items[workContext.currentNum === workContext.items.length - 1 ? 0 : workContext.currentNum+1].title)
    }
  },[workContext])

  return (
    <div className={`page ${appContext.currentPage} ${pageContext.content.length > 0 && pageContext.content[0].theme ? pageContext.content[0].theme : 'light-theme'} ${pageContext.content.length > 0 && pageContext.content[0].client ? 'work' : ''}`}>
      <div className="page__section top" style={{minHeight: `${ pageContext.content.length > 0 && !pageContext.content[0].media ? 'auto' : '' }`}}>
        <div className="copy">
          {
            pageContext.content.length > 0 && pageContext.content[0].description ? (
              documentToReactComponents(pageContext.content[0].description.json, options)
            ) : null
          }
        </div>
        {
          pageContext.content.length > 0 && pageContext.content[0].media ? (
            <div className="section__background">
              { displayMedia(pageContext.content[0].media, null, true) }
            </div>
          ) : null
        }
      </div>
      <div className={`page__section expanded`}>
        {
          pageContext.content.length > 0 && pageContext.content[0].client ? (
            <h3 className="client-name">{pageContext.content[0].client}</h3>
          ) : null
        }
        {
          pageContext.content.length > 0 && pageContext.content[0].expanded ? (
            documentToReactComponents(pageContext.content[0].expanded.json, options)
          ) : null
        }
        <div className="expanded__bottom">
        {
          pageContext.content.length > 0 && pageCategory === 'work' ?(
            <div className="back-btn"><a href={`/${pageCategory}`}>{`Go Back`}</a></div>
          ) : null
        }
        {
          pageCategory === 'work' ? (
            <div className="more-btn"><a href={`/work/${moreLink}`}>{`${nextTitle} >`}</a></div>
          ) : null
        }
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default FullPage;