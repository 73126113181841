import React, { useEffect, useState, useRef } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useHistory } from "react-router-dom";
import { Parallax } from 'react-parallax';
import arrowImg from '../images/arrow.svg';
import AppContext from '../contexts/AppContext';
import { PageContext } from '../contexts/PageContext';
import Footer from '../components/Footer';
import Hero from '../components/Hero';

const About = () => {
	const appContext = React.useContext(AppContext);
  const pageContext = React.useContext(PageContext);
  const history = useHistory();
  const parallaxEl = useRef(null);
  const currentPage = window.location.pathname.split('/').pop();
  const pageCategory = window.location.pathname.split('/')[1];

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <h2 className="headline">{children}</h2>,
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <p>{children}</p>
        )
      }
    }
  }

  useEffect(() => {
  	appContext.setCurrentPage('about');
    // scroll to top of page
    window.scroll(0,0);
  }, [pageContext.content]);

  useEffect(() => {
    parallaxEl.current?.style.setProperty('--imageNum', pageContext.embeddedAssets.length)
  },[pageContext.embeddedAssets])

  return (
  	<div className={`page about ${pageContext.content[0]?.theme}`}>
  	 <div className="page__section top">
        <div className="copy">
          { documentToReactComponents(pageContext.content[0]?.description.json, options) }
        </div>
      </div>
      <div className="page__section expanded" ref={parallaxEl}>
        <div className="parallax">
          <div className="parallax__copy">
            {
              documentToReactComponents(pageContext.content[0]?.expanded.json, options)
            }
            <div className="link" onClick={ () => history.push('/work') }>Check out some of our latest work <div className="expand-icon"><img src={arrowImg} /></div></div>
          </div>
          <div className="parallax__images">
            {
              pageContext.embeddedAssets.map((a,i) => {
                return (
                  <Parallax key={i} bgImage={a.url} strength={200}>
                    <div className="parallax-image"></div>
                  </Parallax>
                )
              })
            }
          </div>
        </div>
      </div>
      <Footer />
  	</div>
  )
}

export default About;