import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import AppContext from './AppContext';
const PageContext = React.createContext();

const PageProvider = (props) => {
  const appContext = React.useContext(AppContext);
  const [content, setContent] = useState([]);
  const [embeddedAssets, setEmbeddedAsset] = useState([]);
  const [embeddedEntries, setEmbeddedEntries] = useState([]);
  const [carouselData, setCarouselData] = useState([]);
  const [carouselSpeed, setCarouselSpeed] = useState(null);
  const [pageTheme, setPageTheme] = useState('');
  const { slug } = useParams();
  const queryType = props.pageId ? 'pageId' : 'slug';

  const getCarouselItems = (assetId) => {
    const GET_CAROUSEL_DATA = `query{
      contentItem(id: "${(assetId)}") {
        title
        subTitle
        hideTitle
        slug
        client
        media {
          contentType
          url
        }
      }
    }`;

    axios({
        url: `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_SPACE}/environments/${appContext.env}`,
        method: "GET",
        headers: {
          "Authorization": `Bearer ${appContext.cDelivery}`,
          "Content-Type": "application/json"
        },
        params: {
          query: GET_CAROUSEL_DATA
        }
      })
      .then(({ data }) => {
        setCarouselData(carouselData => [...carouselData, {
          id: assetId,
          slug: data.data.contentItem.slug,
          client: data.data.contentItem.client,
          title: data.data.contentItem.title,
          subTitle: data.data.contentItem.subTitle,
          hideTitle: data.data.contentItem.hideTitle,
          url: data.data.contentItem.media.url,
          contentType: data.data.contentItem.media.contentType
        }])
      })
      .catch(console.error)
  }

  const getEmbeddedEntry = (entryId) => {
    const GET_EMBEDDED_ENTRY_QUERY = `query {
      entryCollection(where: {
        sys: {
          id: "${entryId}"
        }
      }) {
        items {
          __typename
          ...on Hero {
            label
            theme
            headline
            slug
            copy {
              json
            }
            backgroundImage {
              url
            }
          }
          ...on Carousel {
            speed
            itemsCollection {
              items {
                sys {
                  id
                }
              }
            }
          }
          ...on ContentItem {
            title
            subTitle
            client
            theme
            slug
            description {
              json
            }
            expanded {
              json
            }
            media {
              title
              contentType
              description
              url
            }
          }
          ...on ContentImage {
            title
            mediaCollection {
              items {
                url
              }
            }
          }
          ...on TwoColumnModule {
            title
            mediaCollection {
              items {
                url
              }
            }
            caption1
            caption2
          }
          ...on LoopingVideo {
            video {
              url
            }
            caption
          }
          ...on StandardVideo {
            thumbnail {
              url
            }
            video {
              url
            }
            videoCaption
          }
          ...on TextBlock {
            theme
            copy {
              json
            }
          }
          ...on Banner {
            title
            bannerText
          }
          ...on PeopleItem {
            name
            role
            image {
              url
            }
            url
          }
        }
      }
    }`;

    const entryData = axios({
        url: `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_SPACE}/environments/${appContext.env}`,
        method: "GET",
        headers: {
          "Authorization": `Bearer ${appContext.cDelivery}`,
          "Content-Type": "application/json"
        },
        params: {
          query: GET_EMBEDDED_ENTRY_QUERY
        }
      })
      .then(({ data }) => {
        // Which kind of embedded entry?
        if (data.data.entryCollection.items[0].__typename === 'Banner') {
          setEmbeddedEntries(embeddedEntries => [...embeddedEntries, {
              type: 'Banner',
              id: entryId,
              title: data.data.entryCollection.items[0].title,
              bannerText: data.data.entryCollection.items[0].bannerText
          }]);
        }

        if (data.data.entryCollection.items[0].__typename === 'Carousel') {
          setCarouselSpeed(data.data.entryCollection.items[0].speed)
          data.data.entryCollection.items[0].itemsCollection.items.map(c => getCarouselItems(c.sys.id));
          setEmbeddedEntries(embeddedEntries => [...embeddedEntries, {
            type: 'Carousel',
            id: entryId
          }]);
        }

        if (data.data.entryCollection.items[0].__typename === 'ContentImage') {
          setEmbeddedEntries(embeddedEntries => [...embeddedEntries, {
            id: entryId,
            type: 'ContentImage',
            media: data.data.entryCollection.items
          }]);
        }

        if (data.data.entryCollection.items[0].__typename === 'TwoColumnModule') {
          setEmbeddedEntries(embeddedEntries => [...embeddedEntries, {
            id: entryId,
            type: 'TwoColumnModule',
            media: data.data.entryCollection.items,
            caption1: data.data.entryCollection.items[0].caption1,
            caption2: data.data.entryCollection.items[0].caption2
          }]);
        }

        if (data.data.entryCollection.items[0].__typename === 'LoopingVideo') {
          setEmbeddedEntries(embeddedEntries => [...embeddedEntries, {
            id: entryId,
            type: 'LoopingVideo',
            vidUrl: data.data.entryCollection.items[0].video.url,
            caption: data.data.entryCollection.items[0].caption
          }]);
        }

        if (data.data.entryCollection.items[0].__typename === 'StandardVideo') {
          setEmbeddedEntries(embeddedEntries => [...embeddedEntries, {
            id: entryId,
            type: 'StandardVideo',
            vidUrl: data.data.entryCollection.items[0].video.url,
            vidThumb: data.data.entryCollection.items[0].thumbnail.url,
            videoCaption: data.data.entryCollection.items[0].videoCaption
          }]);
        }

        if (data.data.entryCollection.items[0].__typename === 'Hero') {
          setEmbeddedEntries(embeddedEntries => [...embeddedEntries, {
            label: data.data.entryCollection.items[0].label,
            type: 'Hero',
            theme: data.data.entryCollection.items[0].theme,
            id: entryId,
            backgroundImage: data.data.entryCollection.items[0].backgroundImage,
            headline: data.data.entryCollection.items[0].headline,
            copy: data.data.entryCollection.items[0].copy
          }]);
        }

        if (data.data.entryCollection.items[0].__typename === 'TextBlock') {
          setEmbeddedEntries(embeddedEntries => [...embeddedEntries, {
            type: 'TextBlock',
            id: entryId,
            copy: data.data.entryCollection.items[0].copy,
            theme: data.data.entryCollection.items[0].theme
          }]);
        }

        if (data.data.entryCollection.items[0].__typename === 'PeopleItem') {
          setEmbeddedEntries(embeddedEntries => [...embeddedEntries, {
            type: 'PeopleItem',
            id: entryId,
            name: data.data.entryCollection.items[0].name,
            role: data.data.entryCollection.items[0].role,
            image: data.data.entryCollection.items[0].image.url,
            url: data.data.entryCollection.items[0].url
          }]);
        }

        if (data.data.entryCollection.items[0].__typename === 'ContentItem') {
          setEmbeddedEntries(embeddedEntries => [...embeddedEntries, {
            type: 'ContentItem',
            title: data.data.entryCollection.items[0].title,
            subTitle: data.data.entryCollection.items[0].subTitle,
            hideTitle: data.data.entryCollection.items[0].hideTitle,
            client: data.data.entryCollection.items[0].client,
            type: 'ContentItem',
            id: entryId,
            slug: data.data.entryCollection.items[0].slug,
            theme: data.data.entryCollection.items[0].theme,
            media: data.data.entryCollection.items[0].media,
            description: data.data.entryCollection.items[0].description,
            expanded: data.data.entryCollection.items[0].expanded
          }]);

          //get assets inside of ContentItem embedded entry
          if (data.data.entryCollection.items[0].expanded) {
            data.data.entryCollection.items[0].expanded.json.content.map(n => {
              if (n.nodeType === 'embedded-asset-block') {
                getEmbeddedAsset(n.data.target.sys.id)
              }

              if (n.nodeType === 'embedded-entry-block') {
                getEmbeddedEntry(n.data.target.sys.id)
              }
            })
          }

          if (data.data.entryCollection.items[0].description) {
            data.data.entryCollection.items[0].description.json.content.map(n => {
              if (n.nodeType === 'embedded-asset-block') {
                getEmbeddedAsset(n.data.target.sys.id)
              }

              if (n.nodeType === 'embedded-entry-block') {
                getEmbeddedEntry(n.data.target.sys.id)
              }
            })
          }

        }
      })
      .catch(console.error)
  }

  const getEmbeddedAsset = (assetId) => {
    const GET_EMBEDDED_ASSET_QUERY = `query{
      asset(id: "${(assetId)}") {
        ...on Asset {
          contentType
          description
          url
        }
      }
    }`;

    const assetData = axios({
        url: `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_SPACE}/environments/${appContext.env}`,
        method: "GET",
        headers: {
          "Authorization": `Bearer ${appContext.cDelivery}`,
          "Content-Type": "application/json"
        },
        params: {
          query: GET_EMBEDDED_ASSET_QUERY
        }
      })
      .then(({ data }) => {
        setEmbeddedAsset(embeddedAssets => [...embeddedAssets, {
          id: assetId,
          description: data.data.asset.description,
          url: data.data.asset.url,
          contentType: data.data.asset.contentType
        }]);
      })
      .catch(console.error)
  }

  const getQuery = () => {
    switch (queryType) {
      case 'pageId':
        return `query {
          page(id: "${props.pageId}") {
            title
            slug
            contentCollection {
              items {
                __typename
                ...on Hero {
                  label
                  headline
                  theme
                  slug
                  copy {
                    json
                  }
                  backgroundImage {
                    url
                  }
                }
                ...on Carousel {
                  speed
                  itemsCollection {
                    items {
                      sys {
                        id
                      }
                    }
                  }
                }
                ...on CustomSlideshow {
                  headline
                  mainCopy
                  slug
                  imagesCollection {
                    items {
                      url
                    }
                  }
                }
                ...on ContentItem {
                  title
                  subTitle
                  hideTitle
                  theme
                  slug
                  client
                  description {
                    json
                  }
                  expanded {
                    json
                  }
                  media {
                    title
                    description
                    contentType
                    url
                  }
                }
                ...on NewsItem {
                  title
                  date
                  source
                  image {
                    url
                  }
                  size
                  url
                }
              }
            }
          }
        }`;
        break;
      case 'slug':
        return `query {
          contentItemCollection(where: {
            slug: "${slug}"
          }) {
            items {
              theme
              title
              client
              __typename
              description {
                json
              }
              expanded {
                json
              }
              media {
                title
                contentType
                url
              }
            }
          }
        }`
        break;
    }
  }

  const getContent = () => {
    const contentfulQuery = getQuery();

    axios({
        url: `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_SPACE}/environments/${appContext.env}`,
        method: "GET",
        headers: {
          "Authorization": `Bearer ${appContext.cDelivery}`,
          "Content-Type": "application/json"
        },
        params: {
          query: contentfulQuery
        }
      })
      .then(({ data }) => {
        const dataPath = props.pageId ? data.data.page.contentCollection.items : data.data.contentItemCollection.items;

        if(queryType === 'pageId') {
          setPageTheme(data.data.page.theme)
        }

        dataPath.forEach((v, i) => {
          setContent(content => [...content, v])

          if (v.__typename === 'Carousel') {
            setCarouselSpeed(v.speed)
            v.itemsCollection.items.map(c => getCarouselItems(c.sys.id))
          }

          if (v.expanded) {
            v.expanded.json.content.forEach(n => {
              if (n.nodeType === 'embedded-asset-block') {
                getEmbeddedAsset(n.data.target.sys.id)
              }
              if (n.nodeType === 'embedded-entry-block') {
                getEmbeddedEntry(n.data.target.sys.id)
              }
            })
          }

          if (v.description) {
            v.description.json.content.forEach(n => {
              if (n.nodeType === 'embedded-asset-block') {
                getEmbeddedAsset(n.data.target.sys.id)
              }
              if (n.nodeType === 'embedded-entry-block') {
                getEmbeddedEntry(n.data.target.sys.id)
              }
            })
          }
        })

      })
      .catch(console.error)
  }

  useEffect(() => {
    return getContent();
  }, [])

  return (
    <PageContext.Provider value={{content: content, embeddedAssets: embeddedAssets, embeddedEntries: embeddedEntries, carouselData: carouselData, carouselSpeed: carouselSpeed, pageTheme: pageTheme }}>
      {props.children}
    </PageContext.Provider>
  )
}

const PageConsumer = PageContext.Consumer;
export { PageContext, PageProvider, PageConsumer }