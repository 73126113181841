import React from 'react';
import AppContext from '../contexts/AppContext';
import { useState, useEffect } from 'react';
import Flickity from 'react-flickity-component';
import 'flickity/dist/flickity.min.css';

const mapOrder = (array, order, key) => {
  array.sort( function (a, b) {
    var A = a[key], B = b[key];
    
    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
    
  });
  return array;
};

const Carousel = (props) => {
  const appContext = React.useContext(AppContext);
  const [autoplay, setAutoplay] = useState(true);
  let flkty = null;
  const o = [...props.carouselOrder].map(n => n.sys.id);
  const newOrder = mapOrder(props.items, o, 'id');

  const flickityOptions = {
    autoPlay: props.speed * 1000,
    pauseAutoPlayOnHover: false,
    wrapAround: true,
    prevNextButtons: false,
    setGallerySize: false
  }

  useEffect(() => {
  	return props.speed ? (
  		flkty.on('ready', () => {
	  		return flkty.pageDots ? (
	  			document.getElementsByClassName('flickity-page-dots')[0].style.display = 'block',
		  		flkty.pageDots.dots.map((d,i) => {
		  			d.style.width = `calc(100% / ${flkty.pageDots.dots.length} - 0.6vw)`
		  		})
	  		) : null
	  	})
  	) : null
  }, [props.speed])

  return (
    <div className={`carousel-container ${autoplay ? 'autoplay' : ''} speed-${props.speed}`} onClick={()=> { setAutoplay(false) }}>
	  	{
	  	// render carousel once speed is set
	  	props.speed ? (
	  		<Flickity
	  			flickityRef={c => (flkty = c)}
		    	className={
		    		`${appContext.itemExpanded ? 'hidden': ''} content__item
		    	`}
		      elementType={'div'}
		      options={flickityOptions}
		      >
					{
						newOrder.map((v,i) => {
							return (
								<div className="carousel__item" key={i}>
									{
										v.hideTitle ? null : (
											<div className="carousel__item__title"><a href={`/${v.client ? 'work' : ''}/${v.slug}`} rel="noreferrer"><span className="main">{v.title}</span></a> <span className="subtitle"><a href={`/${v.client ? 'work' : ''}/${v.slug}`} rel="noreferrer">{v.subTitle || null}</a></span></div>
										)
									}
									<div className="carousel__item__background">
										<a href={`/${v.client ? 'work' : ''}/${v.slug}`} rel="noreferrer">
										{ v.contentType.includes('video') ? (
											<video muted autoPlay loop playsInline src={v.url}></video>
										) : <img src={v.url} /> }
										</a>
									</div>
								</div>
							)
						})
					}
					</Flickity>
	  	) : null
	  	}
  	</div>
  )
}

export default Carousel;