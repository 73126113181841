import React, { useEffect, useState, useRef } from 'react';
import AppContext from '../contexts/AppContext';
import { PageContext } from '../contexts/PageContext';
import Banner from './Banner';
import Footer from './Footer';
import Hero from './Hero';
import ListItem from './ListItem';
import ContentItem from './ContentItem';
import Carousel from './Carousel';
import CustomSlideshow from './CustomSlideshow';
import Contact from './Contact';
import TextBlock from './TextBlock';
import About from '../pages/About';
import InstagramFeed from './InstagramFeed';
import Partners from './Partners';
import OurPeople from './OurPeople';
import NewsItem from './NewsItem';

const Page = () => {
  const appContext = React.useContext(AppContext);
  const pageContext = React.useContext(PageContext);
  const currentPage = window.location.pathname.split('/').pop();
  const pageEl = useRef(null);

  useEffect(() => {
    appContext.setHomeActive(!currentPage);
    appContext.setHomeEl(pageEl.current);
  
    appContext.setCurrentPage(currentPage || 'home');
  }, []);

  const getComponent = (item, index) => {
    const typeName = item.__typename;

    if(item.label?.toLowerCase().includes('contact')){
      return <Contact key={index} content={item} />
    }

    if(currentPage === 'about'){
      return <About key={index} content={item} />
    }

    if(item.title?.toLowerCase().includes('partners')){
      return <Partners key={index} content={item} partnerLogos={pageContext.embeddedAssets} />
    }

    if(item.title?.toLowerCase().includes('people')){
      return <OurPeople key={index} content={item} peopleItems={pageContext.embeddedEntries} currentPage={currentPage} />
    }

    if(item.title?.toLowerCase().includes('instagram')){
      return <InstagramFeed key={index} />
    }

    switch (typeName) {
      case 'Banner':
        return <Banner key={index} content={item} />
        break;
      case 'Hero':
        return <Hero key={index} content={item} />
        break;
      case 'CustomSlideshow':
        return <CustomSlideshow key={index} content={item} images={item.imagesCollection?.items} />
        break; 
      case 'ContentItem':
        if (appContext.currentPage === 'home' || appContext.currentPage === 'work') {
          return <ListItem key={index} content={item} />
        }
        else {
          return <ContentItem key={index} content={item} />
        }
        break;
      case 'Carousel':
        const carouselObj = pageContext.content.filter(c => c.__typename === 'Carousel')[0].itemsCollection.items;

        return <Carousel key={index} speed={pageContext.carouselSpeed} items={pageContext.carouselData} carouselOrder={carouselObj} />
        break;
      case 'TextBlock':
        return <TextBlock key={index} theme={item.theme} copy={item.copy} />
        break;
      case 'NewsItem':
        return <NewsItem key={index} content={item} />
        break;
    }
  }

  return (
    <div className={`page list ${appContext.currentPage} ${pageContext.pageTheme ?? ''}`} ref={pageEl}>
			{ pageContext.content.map((item, index) => getComponent(item, index)) }
      <Footer />
		</div>
  )
}

export default Page;