import React, { useEffect } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Banner from './Banner';

const Partners = ({content, partnerLogos}) => {
	const contentItems = content.description.json.content;

	const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <h2 className="headline">{children}</h2>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>
    }
  }

	return (
		<div className="partners content__item">
			<Banner text="Our Proud Partners ↓" />
			<div className="partners__content">
				{documentToReactComponents(contentItems[0])}
				<div className="partners__logos">
					{ partnerLogos.map((l,i) => <img key={i} src={l.url} />) }
				</div>
			</div>
		</div>
	)
}

export default Partners;