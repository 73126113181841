import React from 'react';

const NewsItem = (props) => {
  const squareSize = props.content.size === '2:1' ? 2 : 1;

  return (
    <div className={`content__item news__item size-${squareSize}`}>
      <div className="news__item__content">
        <p className="news__item__content__info">
          <span className="date">{props.content.date}</span>
          <span className="source">{props.content.source}</span>
        </p>
        <p className="news__item__content__title">{props.content.title}</p>
      </div>
      <a href={props.content.url} target="_blank"></a>
      <div className="news__item__background" style={{backgroundImage: `url(${props.content.image.url})`}}></div>
    </div>
  )
}

export default NewsItem;