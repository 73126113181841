import React from 'react';
import { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";
import { AppProvider } from './contexts/AppContext';
import { PageProvider } from './contexts/PageContext';
import { WorkProvider } from './contexts/WorkContext';
import Nav from './components/Nav';
import FullPage from './components/FullPage';
import Page from './components/Page';
import About from './pages/About';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';

const App = () => {
  const [navExpanded, setNavExpanded] = useState(false);
  const [homeActive, setHomeActive] = useState(false);
  const [currentPage, setCurrentPage] = useState('');
  const [homeTop, setHomeTop] = useState(0);
  const [homeEl, setHomeEl] = useState(null);
  const [scrollY, setScrollY] = useState(0);
  const pageCategory = window.location.pathname.split('/')[1];
  const [orangeNav, setOrangeNav] = useState(false);

  const cPreview = process.env.REACT_APP_PREVIEW;
  const cDelivery = process.env.REACT_APP_DELIVERY;
  const env = window.location.href.includes('staging') || window.location.href.includes('localhost') ? 'staging' : 'production';

  const scrollHandler = () => {
    setScrollY(window.scrollY);
  }

  useEffect(() => {
    // scroll to top of page
    window.scroll(0,0);
  },[]);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    window.addEventListener('wheel', scrollHandler);
    window.addEventListener('touchmove', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
      window.removeEventListener('wheel', scrollHandler);
      window.removeEventListener('touchmove', scrollHandler);
    };
  }, [homeEl, currentPage]);

  return (
    <div className={`app ${ navExpanded ? 'nav-expanded' : ''} ${currentPage === 'home' ? 'home' : ''}`}>
      <AppProvider value={{cPreview: cPreview, cDelivery: cDelivery, env: env, setScrollY: setScrollY, setHomeActive: setHomeActive, homeActive: homeActive, homeTop: homeTop, setHomeTop: setHomeTop, scrollY: scrollY, currentPage: currentPage, pageCategory: pageCategory, setCurrentPage: setCurrentPage, setHomeEl: setHomeEl, homeEl: homeEl, orangeNav: orangeNav, setOrangeNav: setOrangeNav}}>
      <Router>
        <Nav navExpanded={navExpanded} setNavExpanded={setNavExpanded} />
        <Route exact path="/">
          <PageProvider pageId="2UQvbPwcMbWIdOQuLTpU4H">
            <Page />
          </PageProvider>
        </Route>
        <Route exact path="/about">
          <PageProvider pageId="5vFm4QHUzNjW6IhO8BIXt9">
            <About />
          </PageProvider>
        </Route>
        <Route exact path="/work">
          <PageProvider pageId="6gHi2rROql8YIyeOijQb0B">
            <Page />
          </PageProvider>
        </Route>
        <Route exact path="/culture">
          <PageProvider pageId="5xCwF06YvXsFak1Ds04W1s">
            <Page />
          </PageProvider>
        </Route>
        <Route exact path="/news">
          <PageProvider pageId="6KyJGAb2KbGMrC4uGCrMRF">
            <Page />
          </PageProvider>
        </Route>
        <Route exact path="/strategy-studio">
          <PageProvider pageId="5TFmb4wZtP60ghaGl3YK3i">
            <Page />
          </PageProvider>
        </Route>
        <Route path="/contact">
          <PageProvider pageId="5NKZO3G0FF3fQhhAjxvxdK">
            <Page />
          </PageProvider>
        </Route>
        <Route exact path="/terms-and-conditions">
          <TermsAndConditions />
        </Route>
        <Route exact path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/work/:slug">
          <WorkProvider>
          <PageProvider>
            <FullPage />
          </PageProvider>
          </WorkProvider>
        </Route>
        <Route path="/content/:slug">
          <PageProvider>
            <FullPage />
          </PageProvider>
        </Route>
      </Router>
      </AppProvider>
    </div>
  )
}

export default App;